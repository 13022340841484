.slick-next {
  position: absolute !important;
  top: 45% !important;
  right: 1% !important;
  height: 50px !important;
  width: 50px !important;
  background: url(../assets/next-arrow.png) center center/contain no-repeat !important;
  border: none !important;
  color: transparent !important;
  z-index: 2 !important;
  outline: none !important;
  opacity: 0.7 !important;
}

.slick-prev {
  position: absolute !important;
  top: 45% !important;
  left: 1% !important;
  height: 50px !important;
  width: 50px !important;
  background: url(../assets/prev-arrow.png) center center/contain no-repeat !important;
  border: none !important;
  color: transparent !important;
  z-index: 2 !important;
  outline: none !important;
  opacity: 0.7 !important;
}

.slick-next::before,
.slick-prev::before {
  display: none !important;
}

.slick-prev:hover,
.slick-next:hover {
  opacity: 1 !important;
  cursor: pointer !important;
}

/* https://github.com/akiran/react-slick/issues/1343#issue-350654512 */
.slick-track > .slick-slide:first-child {
  opacity: 1 !important;
}

.slick-slider {
  height: 300px;
}

.slick-list {
  height: 100%;
}

.slick-dots {
  height: 10px;
  bottom: 20px;
  text-align: right;
}

.featuredArtWorkText {
  position: absolute;
  z-index: 2;
  text-align: left;
  color: #fff;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.8);
  font-weight: 700;
  letter-spacing: -0.8px;
  margin: 0 50px 0 50px;
  margin-left: 0;
  bottom: 190px;
  left: 55px;
  font-size: 150%;
}

.featuredText {
  position: absolute;
  z-index: 2;
  bottom: 60px;
  left: 55px;
  text-align: left;
  color: #fff;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.8);
  font-weight: 700;
  letter-spacing: -0.8px;
  margin: 0 50px 0 50px;
  margin-left: 0;
  overflow: hidden;
  height: 125px;
  font-size: 150%;
}

.featuredButton {
  position: absolute;
  text-align: center;
  padding: 4px 20px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  border: none;
  background: #fff;
  color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
  border-radius: 50px;
  width: fit-content;
  cursor: pointer;
  bottom: 8%;
  left: 25px;
}

@media (max-width: 500px) {
  .featuredArtWorkText {
    font-size: 110%;
  }
  .slick-arrow {
    height: 30px;
  }
  .featuredText {
    font-size: 120%;
  }
}

@media (min-width: 650px) {
  .featuredText {
    bottom: 60px;
    font-size: 150%;
  }
}

@media (min-width: 960px) {
  .slick-slider {
    height: 400px;
  }
}
