.featuredCollectionItem {
    width: 31.33%;
    height: 100%;
    border-radius: 8px 8px 20px 20px;
    margin: 0 20px;
}

.featuredCollectionsInnerWrapperClass {
    width: 100% !important;
    height: 100%;
}

.featuredCollectionItemImage {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 80%;
    width: 100%;
    border-radius: 8px 8px 9px 9px;
}

.featuredCollectionItemText {
    height: 20%;
    background: #fff;
    width: 100%;
    border-radius: 0 0 20px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.featuredCollectionItemTextTitle {
    float: left;
    width: 70%;
    height: 20px;
    font-size: 16px;
    font-weight: 800;
    letter-spacing: -.8px;
    overflow: hidden;
    color: #333 !important;
}
