.story {
  padding-top: 33.333%;
  width: 33.3333%;
  margin-bottom: 20px;
  position: relative;
  /* Padding on the root story causes the image to extend further. Setting to 0 sets it to the smallest possible square size */
  max-height: 0;
  animation: fadein 1s;
}

.date {
  position: absolute;
  right: 35px;
  top: 10px;
  border-radius: 20px;
  padding: 4px 10px;
  background: rgba(255, 255, 255, 0.7);
  font-weight: 600;
  font-family: "Montserrat";
  font-size: 14px;
  letter-spacing: -0.7px;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
