/* Big tablet to 1200px (widths smaller taht the 1140px row) */
@media only screen and (max-width: 1200px) {

    
    
}


/* Small tablet to big tablet: from 768px to 1023px */
@media only screen and (max-width: 1023px) {
      
}



/* Small phones to small tablets: from 481px to 767px */
@media only screen and (max-width: 767px) {
    
}



/* Small phones: from 0 to 480px */
@media only screen and (max-width: 480px) {
    .date {
        right: 10px;
        top: 5px;
        padding: 2px 5px;
        font-size: 12px;
    }
    
}
