/* This closes the suggestion list */
.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

.exploreByMapImage {
  width: 100%;
  object-fit: cover;
  object-position: center;
}

@media (max-width: 860px) {
 
  .googleMapsRoot {
    height: 400px;
  }
  .exploreByMapImage {
    height: 400px;
  }
}

@media (min-width: 861px) {
  .googleMapsRoot {
    height: 500px;
  }
  .exploreByMapImage {
    height: 500px;
  }
}

@media (max-width: 550px) {
  .googleMapsRoot {
    height: 300px;
  }
  .exploreByMapImage {
    height: 300px;
  }
}