.menu-wrapper {
    font-size: 16px;
    width: 100%;
    height: 40px;
}

.menu-item-wrapper {
    padding: 10px 2%;
    font-weight: 600;
    border-right: 1px solid #fff;
    white-space: nowrap;
    text-decoration: none;
    display: inline-block;
    font-family: Avenir Next, HelveticaNeue,'Montserrat';
    color: #FFF;
}

