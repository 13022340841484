body {
  padding: 0;
  margin: 0;
  height: 100%;
  color: #666;
  font-family: Avenir Next, "Montserrat", "OpenSans", Helvetica, Arial,
    sans-serif;
  line-height: 1.3;
  -webkit-font-smoothing: antialiased;
  background: #fff;
  font-size: 18px;
  background: #eef1f3;
}

html,
body,
#root {
  height: 100%;
  background-color: #eef1f3;
}

::-webkit-scrollbar {
  width: 10px;
}

::placeholder {
  color: #b2b2b2;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

/* Hiding Accessibe icon: TODO: [P3MVP-438] clean up the CSS e.g. remove unused attributes */
body
  > div.acsb-trigger.acsb-bg-lead.acsb-trigger-size-medium.acsb-trigger-radius-50\%.acsb-trigger-position-x-right.acsb-trigger-position-y-bottom.acsb-trigger-offset-X-20.acsb-trigger-offset-y-20.acsb-ready {
  background-color: unset !important;
  border-radius: unset !important;
}

.accessiblilityIcon {
  height: 50px !important;
  padding-top: 16px;
  line-height: 32px !important;
  min-width: 50px;
  text-align: center;
  margin-right: 16px;
}

/* Modifying svg style to make it match the other footer items */
body
  > div.acsb-trigger.acsb-bg-lead.acsb-trigger-size-medium.acsb-trigger-radius-50\%.acsb-trigger-position-x-right.acsb-trigger-position-y-bottom.acsb-trigger-offset-X-20.acsb-trigger-offset-y-20.acsb-ready.accessiblilityIcon
  > span.acsb-trigger-icon
  > svg {
  max-width: 25px !important;
  max-height: 25px !important;
}

.acsb-bg-lead {
  background-color: unset !important;
}

body
  > div.acsb-trigger.acsb-bg-lead.acsb-trigger-radius-50\%.acsb-trigger-offset-X-20.acsb-trigger-offset-y-20.acsb-ready.acsb-mobile.acsb-trigger-position-x-right.acsb-trigger-position-y-bottom.acsb-trigger-size-small {
  background-color: unset !important;
  left: unset !important;
  right: 37px !important;
  top: unset !important;
  bottom: 27px !important;
}

body
  > div.acsb-trigger.acsb-bg-lead.acsb-trigger-radius-50\%.acsb-trigger-offset-X-20.acsb-trigger-offset-y-20.acsb-mobile.acsb-trigger-position-x-right.acsb-trigger-position-y-bottom.acsb-trigger-size-small.acsb-ready.accessiblilityIcon
  > span.acsb-trigger-icon
  > svg {
  max-width: 25px !important;
  max-height: 25px !important;
}

/* SVG icon in mobile */
body
  > div.acsb-trigger.acsb-bg-lead.acsb-trigger-radius-50\%.acsb-trigger-offset-X-20.acsb-trigger-offset-y-20.acsb-mobile.acsb-trigger-position-x-right.acsb-trigger-position-y-bottom.acsb-trigger-size-small.acsb-ready
  > span.acsb-trigger-icon
  > svg {
  max-width: 25px !important;
  max-height: 25px !important;
  top: unset !important;
  left: unset !important;
  bottom: unset !important;
  right: unset !important;
}

.acsb-trigger.acsb-trigger-position-y-bottom {
  bottom: 17px;
}

/* For moving the plus / minus for zooming in the Advanced Search page up to prevent the footer from blocking it (seems to affect all other instances of the map */
.gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
  bottom: 160px !important;
}

@media (max-width: 321px) {
  body
    > div.acsb-trigger.acsb-bg-lead.acsb-trigger-radius-50\%.acsb-trigger-offset-X-20.acsb-trigger-offset-y-20.acsb-ready.acsb-mobile.acsb-trigger-position-x-right.acsb-trigger-position-y-bottom.acsb-trigger-size-small {
    background-color: unset !important;
    left: unset !important;
    right: 4px !important;
    top: unset !important;
    bottom: 27px !important;
  }
}
